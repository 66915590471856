import React from "react";
import { config, useSpring } from "react-spring";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import Img from "gatsby-image";
import { ChildImageSharp } from "../types";
import { graphql } from "gatsby";

type PageProps = {
  data: {
    aboutUs: ChildImageSharp;
  };
};

const About: React.FunctionComponent<PageProps> = ({ data: { aboutUs } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout color="#fbfbfb">
      <SEO
        title="Artist | Cosmos on Canvas"
        desc="Hi. I'm Riddhi Srinivas! I am an artist focused on pouring acrylics on canvas. I use different techniques in expressing my art. Each idea manifests in its own individual style. I am a nurse by profession and full-time mom by choice.."
      />
      <AnimatedBox
        style={pageAnimation}
        py={[6, 6, 6, 8]}
        px={[6, 6, 8, 6, 8, 13]}
      >
        <h1>Hi. I'm Riddhi Srinivas!</h1>
        <p>
          I am an artist focused on pouring acrylics on canvas. I use different
          techniques in expressing my art. Each idea manifests in its own
          individual style. I am a nurse by profession and full-time mom by
          choice.
        </p>
        <p>
          <a
            href="https://www.instagram.com/cosmos_on_canvas"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>{" "}
          -{" "}
          <a
            href="https://www.facebook.com/cosmos.on.canvas"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook page
          </a>
        </p>
        <p>*** DM for inquiries, prices and commissions. ***</p>
        <Img fluid={aboutUs.childImageSharp.fluid} />
      </AnimatedBox>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query About {
    aboutUs: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "riddhi-about-us" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
